import slugify from 'slugify';

interface Options {
  name: string;
  sku: String;
}

export default function articleSlug({ name, sku }: Options): string {
  return slugify(`${name}-${sku}`);
}
