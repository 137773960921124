export interface SumParams {
  accountId: string;
  action: string;
  customerCountry: string;
  orderReference: string;
  passphrase: string;
  costCenter?: string;
}

export default async function bpostChecksum({
  accountId,
  action,
  customerCountry,
  orderReference,
  passphrase,
  costCenter,
}: SumParams) {
  const url = [
    `accountId=${accountId}`,
    `action=${action}`,
    ...(costCenter ? [`costCenter=${costCenter}`] : []),
    `customerCountry=${customerCountry}`,
    `orderReference=${orderReference}`,
    passphrase,
  ].join('&');

  const textAsBuffer = new TextEncoder().encode(url);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hash = hashArray
    .map((item) => item.toString(16).padStart(2, '0'))
    .join('');

  return hash;
}
