import { modifier } from 'ember-modifier';

export default modifier(
  (
    element: HTMLElement,
    [sliderOne, sliderTwo, sliderMaxValue, trackColor, sliderColor]: [
      number,
      number,
      number,
      string,
      string
    ]
  ) => {
    const track = trackColor ? trackColor : '#aaaaaa';
    const slider = sliderColor ? sliderColor : '#000000';
    let percent1 = (sliderOne / sliderMaxValue) * 100;
    let percent2 = (sliderTwo / sliderMaxValue) * 100;
    element.style.background = `linear-gradient(to right, ${track} ${percent1}% ,  ${slider}  ${percent1}% ,  ${slider}  ${percent2}%, ${track}  ${percent2}%)`;
  },
  { eager: false }
);
