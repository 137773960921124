import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type { StatusCart } from 'common/src/enums/cart/status.enum';
import type { CartModelInterface } from 'common/src/models/cart';
import type CartItemModel from './cart-item';
import type DeliveryMethodModel from './delivery-method';
import type PromoCodeModel from './promo-code';
import type UserModel from './user';

export default class CartModel extends Model implements CartModelInterface {
  @attr() declare status: StatusCart;
  @attr('number') declare price: number;
  @attr('string') declare delivery_reference: string;
  @attr('number') declare delivery_price: number;
  @attr('string') declare bpost_delivery_method: string;
  @attr('string') declare country: string;
  @attr('string') declare adress: string;
  @attr('string') declare adressName: string;
  @attr('number') declare discountTotal: number;
  @belongsTo('user') declare client: UserModel;
  @belongsTo('delivery-method') declare delivery_method?: DeliveryMethodModel;
  @hasMany('cart-item') declare cartItems: CartItemModel[];
  @belongsTo('promo-code') declare code: PromoCodeModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    cart: CartModel;
  }
}
