import Service, { service } from '@ember/service';
import { queryParam } from 'ember-query-params-service';
import {
  numberSerialization,
  setStringSerialization,
} from 'frontend/utils/query-param';
import type ArticleService from './article';

export interface ArticleFilters {
  minDiameter?: number;
  maxDiameter?: number;
  locale: string;
  material?: string;
  brand?: string;
  shape?: string;
  color?: string;
  categories: string[];
  promo?: boolean;
  novelty?: boolean;
  page?: number;
  size?: number;
  search?: string;
  sort?: string;
}

export const DEFAULT_PAGE_SIZE = 24;
export const DEFAULT_MIN_PAGE = 1;

export default class FilterQueryParamsService extends Service {
  @queryParam(numberSerialization) number: number = DEFAULT_MIN_PAGE;
  @queryParam(numberSerialization) size: number = DEFAULT_PAGE_SIZE;
  @queryParam(numberSerialization) min?: number;
  @queryParam(numberSerialization) max?: number;
  @queryParam(setStringSerialization)
  declare queryCategories: Set<string>;

  @queryParam declare queryBrand: string | undefined;
  @queryParam declare queryMaterial: string | undefined;
  @queryParam declare queryColor: string | undefined;
  @queryParam declare queryShape: string | undefined;
  @queryParam declare sort: string | undefined;
  @queryParam declare search: string | undefined;

  @service declare article: ArticleService;

  public queryParamsToArticleQueryObject(): Omit<ArticleFilters, 'locale'> {
    return {
      color: this.queryColor,
      categories: [...this.queryCategories],
      minDiameter: this.min,
      maxDiameter: this.max,
      shape: this.queryShape,
      search: this.search,
      size: this.size,
      page: this.number,
      brand: this.queryBrand,
      material: this.queryMaterial,
      sort: this.sort,
    };
  }

  public reset() {
    this.queryCategories = new Set();
    this.queryMaterial = undefined;
    this.queryShape = undefined;
    this.queryColor = undefined;
    this.queryBrand = undefined;
    this.search = undefined;
    this.min = this.article.filtersSettings?.minDiameter;
    this.max = this.article.filtersSettings?.maxDiameter;
    this.sort = undefined;
    this.number = DEFAULT_MIN_PAGE;
    this.size = DEFAULT_PAGE_SIZE;
  }
}
