// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type DS from 'ember-data';
import Application from './application';

export default class CartItemSerializer extends Application {
  serializeAttribute(
    snapshot: DS.Snapshot,
    json: Record<string, unknown>,
    key: string,
    attributes: Record<string, unknown>
  ) {
    if (key === 'price') {
      return;
    }

    return super.serializeAttribute(snapshot, json, key, attributes);
  }
}
