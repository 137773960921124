import {
  validateFormat,
  validatePresence,
} from 'ember-changeset-validations/validators';
import type { UserDTO } from 'frontend/services/user';

const formsuserValidation = {
  firstName: validatePresence({
    presence: true,
    message: 'validations.firstName.required',
  }),
  lastName: validatePresence({
    presence: true,
    message: 'validations.lastName.required',
  }),
  role: validatePresence({
    presence: true,
    message: 'validations.role.required',
  }),
  email: validateFormat({
    type: 'email',
    message: 'validations.email.required',
  }),
} as Record<keyof UserDTO, unknown>;

export default formsuserValidation;
