const colors = require('tailwindcss/colors');

module.exports = {
  content: ['./app/**/*.{hbs,js,ts,html}', './tests/**/*.{hbs,js,ts,html}'],
  corePlugins: {},
  plugins: [],
  theme: {
    screens: {
      '2xs': '310px',
      xs: '415px',
      sm: '640px',
      md: '768px',
      tb: { max: '1023px' },
      lg: '1024px',
      lxg: '1140px',
      xl: '1367px',
      '2xl': '1600px',
      '3xl': '1680px',
      '4xl': '1920px',
    },
    extend: {
      fontFamily: {
        primary: ['Dosis', 'sans-serif'],
        secondary: ['Titan One', 'cursive'],
        ternary: ['Poppins', 'sans-serif'],
      },
      colors: {
        primary: '#04617B',
        secondary: '#A7014E',
        warning: '#F7D002',
        error: '#f85464',
        pink: '#F6B7D4',
        salmon: '#f8c1b3',
        cyan: '#94DADC',
        green: '#ceda5d',
        validate: '#26650A',
        'text-neg': '#97B9CE',
        text: '#4B4B4B',
        greenCrehopa: '#CEDA5D',

        'text-disabled': '#a0a0a0',
        background: '#F2F2F2',
        'background-disabled': '#F6F6F6',
        white: colors.white,
        red: colors.red,
        gray: colors.gray,
        black: colors.black,
        transparent: colors.transparent,
        dark: '#393939',
        midgray: '#989898',
        'background-dark': '#434344',
      },
    },
  },
};
