import type emberData__store from '@ember-data/store';
import ArrayProxy from '@ember/array/proxy';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type { TaskGenerator } from 'ember-concurrency';
import { keepLatestTask } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import type ArticleModel from 'frontend/models/article';
import type FetchService from './fetch';
import type FilterQueryParamsService from './filter-query-params';

export interface FilterSettings {
  colors: string[];
  shapes: string[];
  brands: string[];
  categories: { id: string; name: string; count: number }[];
  minDiameter: number;
  maxDiameter: number;
}

export interface Pagination {
  size: number;
  number: number;
}

interface ListArticlesOptions {
  promo?: true;
  novelty?: true;
}

export default class ArticleService extends Service {
  @service declare store: emberData__store;
  @service declare fetch: FetchService;
  @service declare intl: IntlService;
  @service declare filterQueryParams: FilterQueryParamsService;

  @tracked articles: ArrayProxy<ArticleModel> = new ArrayProxy();
  @tracked filtersSettings?: FilterSettings = undefined;

  @keepLatestTask
  public *taskGetArticles(options?: ListArticlesOptions): TaskGenerator<void> {
    this.articles = yield this.store.query('article', {
      ...this.filterQueryParams.queryParamsToArticleQueryObject(),
      locale: this.intl.locale,
      ...options,
    });
  }

  @keepLatestTask
  public *taskGetFilterSettings(): TaskGenerator<void> {
    const response = yield this.fetch.request('articles/filters_settings');
    this.filtersSettings = yield response.json();
    if (!this.filterQueryParams.min) {
      this.filterQueryParams.min = this.filtersSettings!.minDiameter;
    }
    if (!this.filterQueryParams.max) {
      this.filterQueryParams.max = this.filtersSettings!.maxDiameter;
    }
  }

  public async getArticleBySlug(
    slug: string
  ): Promise<ArticleModel | undefined> {
    const sku = slug.split('-').pop();

    const article = await this.store.queryRecord('article', {
      id: sku,
      locale: this.intl.locale,
    });

    return article;
  }

  public listArticles(options: ListArticlesOptions): void {
    taskFor(this.taskGetArticles).perform(options);
  }

  public listFilterSettings(): void {
    taskFor(this.taskGetFilterSettings).perform();
  }
}
declare module '@ember/service' {
  interface Registry {
    article: ArticleService;
  }
}
