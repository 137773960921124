import type { Snapshot } from '@ember-data/store';
import Application from './application';

/* jscpd:ignore-start */
export default class CartSerializer extends Application {
  serializeAttribute(
    snapshot: Snapshot,
    json: Record<string, unknown>,
    key: string,
    attributes: Record<string, unknown>
  ) {
    if (key === 'price') {
      return;
    }

    return super.serializeAttribute(snapshot, json, key, attributes);
  }

  serializeBelongsTo(
    snapshot: Snapshot,
    json: {},
    relationship: Record<string, unknown>
  ) {
    if (relationship.name === 'code') {
      return;
    }

    return super.serializeBelongsTo(snapshot, json, relationship);
  }
}
/* jscpd:ignore-end */
