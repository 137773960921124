import { later } from '@ember/runloop';
import Service, { service } from '@ember/service';
import type { Owner } from '@ember/test-helpers/build-owner';
import { tracked } from 'tracked-built-ins';
import type CartService from './cart';

export interface DeliveryAdress {
  name: string;
  street: string;
  zip: string;
  town: string;
}

export default class MondialRelayWidgetService extends Service {
  @service declare cart: CartService;
  @tracked isOpenModal: boolean = false;
  @tracked deliveryAdress!: DeliveryAdress | undefined;
  // eslint-disable-next-line
  @service fastboot: any;
  public constructor(private owner: Owner) {
    super(owner);
    if (this.fastboot.isFastBoot) {
      return;
    }
    if (this.doesScriptTagExists()) {
      this.setupHtml();
    }
  }

  private get scriptRoot() {
    return this.owner.rootElement as string;
  }

  public open(
    selectedCallback: (code: string, deliveryAdress: DeliveryAdress) => unknown
  ) {
    if (this.fastboot.isFastBoot) {
      return;
    }
    this.isOpenModal = true;
    const country = this.cart.cart.country ?? 'BE';
    later(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ($('#Zone_Widget') as any).MR_ParcelShopPicker({
        // Activer l'affichage Responsive.
        Brand: 'CC206I9K',
        Country: country,
        Target: '#MONDIAL_RELAY_ID',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        OnParcelShopSelected: (data: any) => {
          const delivery_code = (
            document.querySelector('#MONDIAL_RELAY_ID') as HTMLInputElement
          ).value;
          this.deliveryAdress = {
            street: data.Adresse1,
            zip: data.CP,
            name: data.Nom,
            town: data.Ville,
          };
          selectedCallback(delivery_code, this.deliveryAdress);
        },
        Responsive: true,
        // Afficher les résultats sur une carte?
        ShowResultsOnMap: true,
        Theme: 'mondialrelay', // "inpost" pour mettre le thème Inpost
      });
    }, 100);
  }
  public close() {
    if (this.fastboot.isFastBoot) {
      return;
    }
    this.deliveryAdress = undefined;
    this.isOpenModal = false;
  }

  private setupHtml() {
    this.createScriptTag();
  }

  private doesScriptTagExists() {
    return document.querySelector(this.scriptRoot) !== null;
  }

  private createScriptTag() {
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      '//widget.mondialrelay.com/parcelshop-picker/jquery.plugin.mondialrelay.parcelshoppicker.min.js'
    );
    script.setAttribute('id', 'mondial-relay-widget');
    document.querySelector(this.scriptRoot)?.appendChild(script);
  }
}
