import type { TypedBufferedChangeset } from 'ember-form-changeset-validations';

/**
 * Add errors to changeset from backend response
 * Rethrow the error, an error is still an error
 */
export default function mapChangesetErrors(
  changeset: TypedBufferedChangeset,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  e: any,
  key?: string
) {
  // no errors array, just rethrow this strange error
  if (e.errors === undefined) throw e;

  for (const error of e.errors) {
    if (error.status === '400') {
      const p = key ? `${key}.${error.meta.field}` : `${error.meta.field}`;
      const value = changeset.get(p);
      if (value !== undefined) {
        changeset.addError(p, error.detail);
      }
    }
  }

  // rethrow anyway, an error is still an error
  throw e;
}
