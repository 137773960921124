const applicationDomainMap = {
    "www.crehopa.com": "1",
    "crehopa.com": "2",
    "crehopa.be": "3",
    "www.crehopa.be": "4",
    "1": "https://www.crehopa.com",
    "2": "https://crehopa.com",
    "3": "https://crehopa.be",
    "4": "https://www.crehopa.be",
    localhost: "5",
    "5": "http://localhost:4200",
};
export function applicationIdFromDomain(domain) {
    return applicationDomainMap[domain];
}
export function domainFromApplicationId(id) {
    return applicationDomainMap[id];
}
