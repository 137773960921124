import Model, { attr } from '@ember-data/model';
import type { BlogModelInterface } from 'common/src/models/blog';
export default class BlogModel extends Model implements BlogModelInterface {
  @attr() declare createdAt?: Date;
  @attr() declare updatedAt?: Date;
  @attr() declare title: string;
  @attr() declare description: string;
  @attr() declare image: string;
  @attr() declare slug: string;
  @attr() declare og_title: string;
  @attr() declare og_description: string;
  @attr() declare og_image: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    blog: BlogModel;
  }
}
