export var MimeTypes;
(function (MimeTypes) {
    MimeTypes["EXCEL"] = "applcation/vnd.ms-excel";
    MimeTypes["MSWORD"] = "application/msword";
    MimeTypes["ZIP"] = "application/zip";
    MimeTypes["PDF"] = "application/pdf";
    MimeTypes["BMP"] = "image/bmp";
    MimeTypes["GIF"] = "image/gif";
    MimeTypes["JPEG"] = "image/jpeg";
    MimeTypes["PNG"] = "image/png";
    MimeTypes["CSV"] = "text/csv";
    MimeTypes["TEXT"] = "text/plain";
})(MimeTypes || (MimeTypes = {}));
export var ImageMimeTypes;
(function (ImageMimeTypes) {
    ImageMimeTypes["BMP"] = "image/bmp";
    ImageMimeTypes["GIF"] = "image/gif";
    ImageMimeTypes["JPEG"] = "image/jpeg";
    ImageMimeTypes["PNG"] = "image/png";
})(ImageMimeTypes || (ImageMimeTypes = {}));
