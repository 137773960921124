import Model, { attr } from '@ember-data/model';
import type { ArticleModelInterface } from 'common/src/models/article';
import articleSlug from 'frontend/utils/article-slug';
export default class ArticleModel
  extends Model
  implements ArticleModelInterface
{
  @attr('boolean') declare novelty: boolean;
  @attr() declare hole_diameter: number;
  @attr() declare sku: string;
  @attr() declare name: string;
  @attr() declare status: boolean;
  @attr() declare featured: boolean;
  @attr() declare description: string;
  @attr() declare regular_price: number;
  @attr() declare sale_price: number;
  @attr() declare stock_status: boolean;
  @attr() declare stock_quantity: number;
  @attr() declare bundle_quantity: number;
  @attr() declare country: string;
  @attr() declare theme: string;
  @attr() declare material: string;
  @attr() declare specific_material: string;
  @attr() declare brand: string;
  @attr() declare shape: string;
  @attr('number') declare price: number;
  @attr() declare color: string;
  @attr() declare specificity: string;
  @attr() declare length: number;
  @attr() declare width: number;
  @attr() declare height: number;
  @attr() declare width_unit: string;
  @attr() declare height_unit: string;
  @attr() declare length_unit: string;
  @attr() declare weight: number;
  @attr() declare weight_unit: string;
  @attr() declare images: string[];

  get slug() {
    return articleSlug(this);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    article: ArticleModel;
  }
}
