import type emberData__store from '@ember-data/store';
import Service, { service } from '@ember/service';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import type { TypedBufferedChangeset } from 'ember-form-changeset-validations';
import formscontactValidation from 'frontend/validations/contact';
import type CurrentUserService from './current-user';
import type FetchService from './fetch';
import type FlashMessageService from 'ember-cli-flash/services/flash-messages';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';

export interface ContactDTO {
  firstName: string;
  lastName: string;
  email: string;
  content: string;
  subject: string;
}
export default class ContactService extends Service {
  @service declare currentUser: CurrentUserService;
  @service declare store: emberData__store;
  @service declare fetch: FetchService;
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;
  @service declare router: RouterService;

  emptyChangeset(): TypedBufferedChangeset<ContactDTO> {
    return Changeset(
      {
        subject: '',
        email: '',
        firstName: '',
        lastName: '',
        content: '',
      } as ContactDTO,
      lookupValidator(formscontactValidation),
      formscontactValidation
    ) as TypedBufferedChangeset<ContactDTO>;
  }
  currentUserChangeset(): TypedBufferedChangeset<ContactDTO> {
    return Changeset(
      {
        subject: '',
        email: this.currentUser.user?.email,
        firstName: this.currentUser.user?.firstName,
        lastName: this.currentUser.user?.lastName,
        content: '',
      } as ContactDTO,

      lookupValidator(formscontactValidation),
      formscontactValidation
    ) as TypedBufferedChangeset<ContactDTO>;
  }

  async saveChangeset(changeset: TypedBufferedChangeset<ContactDTO>) {
    changeset.execute();

    await this.fetch.request('contact', {
      method: 'POST',
      body: JSON.stringify(changeset.data),
    });
    this.flashMessages.success(this.intl.t('forms.contact.success'));
    await this.router.transitionTo('/');
  }
}
