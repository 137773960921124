import Model, { attr, hasMany } from '@ember-data/model';
import type { UserModelInterface } from 'common/src/models/user';
import type CartModel from './cart';

export default class UserModel extends Model implements UserModelInterface {
  @attr() declare email: string;
  @attr() declare firstName: string;
  @attr() declare lastName: string;
  @attr() declare role: string;
  @attr() declare password: string;
  @hasMany('cart') declare carts?: CartModel[];
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    user: UserModel;
  }
}
