export const numberSerialization = {
  deserialize: (qp?: string) => {
    if (qp === undefined) return qp;
    return +qp;
  },
  serialize: (qp: number) => qp?.toString(),
};

export const setStringSerialization = {
  deserialize: (qp: string): Set<string> => {
    return new Set(qp ? qp.split(',') : []);
  },
  serialize: (value: Set<string>) => {
    return [...value].toString();
  },
};
