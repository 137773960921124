import {
  validateFormat,
  validatePresence,
} from 'ember-changeset-validations/validators';

const loginValidation = {
  email: [
    validatePresence({
      presence: true,
      message: 'validations.email.required',
    }),
    validateFormat({
      type: 'email',
      message: 'validations.email.format',
    }),
  ],
  password: [
    validatePresence({
      presence: true,
      message: 'validations.password.required',
    }),
  ],
};

export default loginValidation;
