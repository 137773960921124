import { validatePresence } from 'ember-changeset-validations/validators';
import type { FormsForgotPasswordDTO } from 'frontend/components/forms/forgot-password/component';

const formsForgotPasswordValidator = {
  email: [
    validatePresence({
      presence: true,
      message: 'validations.email.required',
    }),
  ],
} as Record<keyof FormsForgotPasswordDTO, unknown>;

export default formsForgotPasswordValidator;
