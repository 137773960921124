import config from 'frontend/config/environment';

import EmberRouter from '@embroider/router';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('404', { path: '/*path' });
  this.route('login');
  this.route('profile', function () {
    this.route('orders');
    this.route('downloads');
    this.route('address', function () {
      this.route('edit');
    });
    this.route('edit');
    this.route('vat');
  });
  this.route('forgot-password');
  this.route('reset-password');
  this.route('logout');
  this.route('about-us');
  this.route('contact');
  this.route('general-conditions');
  this.route('faq');
  this.route('partners');
  this.route('blog', function () {
    this.route('details', { path: '/:slug' });
  });
  this.route('catalog', function () {
    this.route('details', { path: '/:id' });
  });
  this.route('promos');
  this.route('novelties');
  this.route('basket');

  this.route('forms', function () {});
});
