import {
  validateFormat,
  validatePresence,
} from 'ember-changeset-validations/validators';
import type { ContactDTO } from 'frontend/services/contact';

const formscontactValidation = {
  firstName: [
    validatePresence({
      presence: true,
      message: 'validations.firstName.required',
    }),
  ],
  lastName: [
    validatePresence({
      presence: true,
      message: 'validations.lastName.required',
    }),
  ],
  email: [
    validateFormat({
      type: 'email',
      message: 'validations.email.format',
    }),
    validatePresence({
      presence: true,
      message: 'validations.email.required',
    }),
  ],
  content: [
    validatePresence({
      presence: true,
      message: 'validations.content.required',
    }),
  ],
  subject: [
    validatePresence({
      presence: true,
      message: 'validations.subject.required',
    }),
  ],
} as Record<keyof ContactDTO, unknown>;

export default formscontactValidation;
