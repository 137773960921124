import Model, { attr } from '@ember-data/model';

export default class DeliveryMethodModel extends Model {
  @attr('string') declare name: string;
  @attr('string') declare widget: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'delivery-method': DeliveryMethodModel;
  }
}
