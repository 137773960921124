import { modifier } from 'ember-modifier';

export default modifier(
  (element: HTMLImageElement) => {
    const magnifier: HTMLDivElement | null =
      document.querySelector('.magnifier');
    if (!magnifier) {
      return;
    }
    const lens = document.createElement('div');
    lens.classList.add('lens');
    let widthRatio = 0;
    let heightRatio = 0;
    element.addEventListener('mouseover', () => {
      if (window.innerWidth > 1024) {
        magnifier.style.display = 'block';

        lens.style.pointerEvents = 'none';
        element.parentElement?.appendChild(lens);

        magnifier.style.backgroundImage = "url('" + element.src + "')";

        widthRatio = magnifier.offsetWidth / lens.offsetWidth;
        heightRatio = magnifier.offsetHeight / lens.offsetHeight;
        magnifier.style.backgroundSize =
          element.width * widthRatio +
          'px ' +
          element.height * heightRatio +
          'px';
      }
    });
    lens.addEventListener('mousemove', (e) => {
      moveLens(e, element, lens, magnifier, widthRatio, heightRatio);
    });
    element.addEventListener('mousemove', (e) => {
      moveLens(e, element, lens, magnifier, widthRatio, heightRatio);
    });
    element.parentElement?.addEventListener('mouseout', () => {
      magnifier.style.display = 'none';
      element.parentElement?.removeChild(lens);
    });
  },
  { eager: false }
);
function moveLens(
  e: MouseEvent,
  element: HTMLImageElement,
  lens: HTMLDivElement,
  magnifier: HTMLDivElement,
  ratioWidth: number,
  ratioHeight: number
) {
  let x =
    e.pageX -
    element.getBoundingClientRect().left -
    window.pageXOffset -
    lens.offsetWidth / 2;
  let y =
    e.pageY -
    element.getBoundingClientRect().top -
    window.pageYOffset -
    lens.offsetHeight / 2;
  if (x > element.width - lens.offsetWidth) {
    x = element.width - lens.offsetWidth;
  }
  if (x < 0) {
    x = 0;
  }
  if (y > element.height - lens.offsetHeight) {
    y = element.height - lens.offsetHeight;
  }
  if (y < 0) {
    y = 0;
  }

  lens.style.left = x + 'px';
  lens.style.top = y + 'px';

  magnifier.style.backgroundPosition =
    '-' + x * ratioWidth + 'px -' + y * ratioHeight + 'px';
}
