import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class HeadDataService extends Service {
  @tracked declare title: undefined | string;
  @tracked declare description: undefined | string;
  @tracked declare image: undefined | string;
  @tracked declare og_title: undefined | string;
  @tracked declare og_description: undefined | string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'head-data': HeadDataService;
  }
}
