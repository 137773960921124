import { later } from '@ember/runloop';
import Service, { service } from '@ember/service';
import type { Owner } from '@ember/test-helpers/build-owner';
import type IntlService from 'ember-intl/services/intl';
import bpostChecksum from 'frontend/utils/bpost-checksum';
import type CartService from './cart';
import type CurrentUserService from './current-user';
import config from 'frontend/config/environment';
import { applicationIdFromDomain } from 'common';

export default class BpostWidgetService extends Service {
  @service declare intl: IntlService;
  @service declare cart: CartService;
  @service declare currentUser: CurrentUserService;
  // eslint-disable-next-line
  @service fastboot: any;

  public constructor(private owner: Owner) {
    super(owner);
    if (this.fastboot.isFastBoot) {
      return;
    }
    if (this.doesScriptTagExists()) {
      this.injectScriptTagIntoRoot();
    }
  }

  public get SHM() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (window as any)['SHM'];
  }

  public get frontUrl() {
    return config.widgets.bpostRedirectUrl;
  }

  public get backUrl() {
    return config.environment === 'production'
      ? config.host
      : 'https://a0d1-2a02-a03f-c391-4000-8081-6edb-a76f-f05f.ngrok.io';
  }

  public get isAvailable() {
    return this.SHM !== undefined;
  }

  private get language() {
    const language = this.intl.primaryLocale;
    if (language === 'fr-fr') {
      return 'FR';
    }
    if (language === 'en-us') {
      return 'EN';
    }
    if (language === 'nl-nl') {
      return 'NL';
    }

    return 'EN';
  }

  private get scriptRoot() {
    return this.owner.rootElement as string;
  }

  public async open() {
    if (this.fastboot.isFastBoot) {
      return;
    }
    const country = this.cart.cart.country;
    const options = {
      accountId: '010696',
      customerCountry: country,
      action: 'START',
      orderReference: this.cart.cart.id,
      passphrase: 'b9185f9d49',
    };
    const checksum = await bpostChecksum(options);

    later(() => {
      this.SHM.open({
        integrationType: 'FULLSCREEN',
        inlineContainerId: 'Zone_BPOST_Widget',
        popupWidth: 1024,
        popupHeight: 768,
        parameters: {
          extra: applicationIdFromDomain(window.location.hostname),
          action: options.action,
          accountId: options.accountId,
          lang: this.language,
          cancelUrl: `${this.frontUrl}/basket?message=${this.intl.t(
            'cart.total.errorBpost.cancel'
          )}`,
          errorUrl: `${this.frontUrl}/basket?message=${this.intl.t(
            'cart.total.errorBpost.error'
          )}`,
          orderReference: options.orderReference,
          customerCountry: options.customerCountry,
          confirmUrl: `${this.backUrl}/api/v1/carts/bpost/${
            this.currentUser.user!.id
          }`,
          checksum,
        },
      });
    }, 300);
  }

  public close() {
    if (this.fastboot.isFastBoot) {
      return;
    }
    this.SHM.close();
  }

  private injectScriptTagIntoRoot() {
    const script = this.createScriptTag();
    document.querySelector(this.scriptRoot)?.appendChild(script);
  }

  private doesScriptTagExists() {
    return document.querySelector(this.scriptRoot) !== null;
  }

  private createScriptTag() {
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://shippingmanager.bpost.be/ShmFrontEnd/shm.js'
    );
    script.setAttribute('id', 'bpost-widget');
    return script;
  }
}
