import type emberData__store from '@ember-data/store';
import { service } from '@ember/service';
import Service from '@ember/service';
import type { SerializedTransport } from 'common';
import type FlashMessageService from 'ember-cli-flash/services/flash-messages';
import type ArticleModel from 'frontend/models/article';
import type CartModel from 'frontend/models/cart';
import type CartItemModel from 'frontend/models/cart-item';
import { tracked } from 'tracked-built-ins';
import type CurrentUserService from './current-user';
import type FetchService from './fetch';
import type { DeliveryAdress } from './mondial-relay-widget';

export interface ProductCart {
  article: ArticleModel;
  quantity: number;
}

export default class CartService extends Service {
  @service declare store: emberData__store;
  @service declare fetch: FetchService;
  @service declare currentUser: CurrentUserService;
  @service declare flashMessages: FlashMessageService;

  @tracked declare cart: CartModel;
  @tracked shippingOptions: SerializedTransport[] = [];

  get tva() {
    return (this.cart.price + this.cart.delivery_price) * 0.21;
  }

  get total() {
    return +this.cart.price + this.cart.delivery_price + this.tva;
  }

  get cartItems(): CartItemModel[] {
    return this.cart?.get('cartItems');
  }

  async setMondialDeliveryInformation(
    ref: string,
    deliveryAdress: DeliveryAdress
  ) {
    this.setDeliveryProperties(deliveryAdress, ref);

    await this.cart.save();
    await this.getCartFromCurrentUser();
  }

  async setGLSDeliveryInformation() {
    this.cart.delivery_price = this.getSerializedOptionPrice();
    await this.cart.save();

    this.cart = await this.taskGetCart();
  }

  async recalculateDeliveryPrice() {
    if (
      this.shippingMethodNeedToBeRecalculated(
        this.cart.delivery_method?.get('widget')
      )
    ) {
      this.cart.delivery_price = this.getSerializedOptionPrice();
      await this.cart.save();
    }
  }

  shippingMethodNeedToBeRecalculated(widget: string | undefined) {
    return widget !== undefined;
  }

  resetDeliveryInformation() {
    this.cart.setProperties({
      adress: null,
      adressName: null,
      delivery_price: null,
      delivery_reference: null,
      bpost_delivery_method: null,
    });
  }

  setDeliveryProperties(
    deliveryAdress: DeliveryAdress,
    deliveryReference: string
  ) {
    this.cart.setProperties({
      adress: `${deliveryAdress.street}, ${deliveryAdress.zip} ${deliveryAdress.town}`,
      adressName: deliveryAdress.name,
      delivery_price: this.getSerializedOptionPrice(),
      delivery_reference: deliveryReference,
    });
  }

  getSerializedOptionPrice() {
    const selectedShipping = this.shippingOptions.find(
      (shippingOption) =>
        shippingOption.name === this.cart.delivery_method?.get('name')
    );

    return Number(selectedShipping?.price);
  }

  async setDeliveryMethod(id?: string) {
    this.resetDeliveryInformation();
    if (id) {
      const delivery = await this.store.findRecord('delivery-method', id);
      this.cart.delivery_method = delivery;
    } else {
      this.cart.delivery_method = undefined;
    }
    await this.cart.save();
    this.cart = await this.taskGetCart();
  }

  async setCountry(country: string) {
    this.cart.country = country;
    await this.cart.save();
    await this.getCartFromCurrentUser();
  }

  async setAdress(adress: string) {
    this.cart.adress = adress;
    await this.cart.save();
    await this.getCartFromCurrentUser();
  }

  async getCartFromCurrentUser() {
    this.cart = await this.taskGetCart();
    if (!this.cart?.country) {
      return;
    }
    this.shippingOptions = await this.fetch
      .request(
        `transports/transports-for-country/${this.cart.country}/${this.cart.price}`
      )
      .then((e) => e.json())
      .then((e) => e.data);
  }

  async addCartItem(product: ProductCart) {
    const getCartItemAlreadyExists: CartItemModel | undefined =
      await this.getCartItemAlreadyExists(product);
    if (getCartItemAlreadyExists) {
      await this.taskUpdateCartItem(getCartItemAlreadyExists, product);
    } else {
      await this.taskCreateCartItemAndAddToCart(product);
    }

    await this.getCartFromCurrentUser();
  }

  async applyPromoCode(code: string) {
    await this.fetch.request(`carts/apply-promo-code/${code}`, {
      method: 'POST',
    });
    await this.getCartFromCurrentUser();
  }

  async removeCartItem(cartItem: CartItemModel) {
    await cartItem.destroyRecord();
    await this.recalculateDeliveryPrice();
    await this.getCartFromCurrentUser();
    this.flashMessages.success('Le produit a bien été retiré du panier');
  }

  async validateCart() {
    await this.fetch.request(`carts/validate`, {
      method: 'POST',
    });
    await this.getCartFromCurrentUser();
    this.flashMessages.success('Le panier a bien été validé');
  }

  async unApplyPromoCode() {
    await this.fetch.request(`carts/unapply-promo-code`, {
      method: 'PATCH',
    });
    await this.getCartFromCurrentUser();
  }

  private async taskGetCart(): Promise<CartModel> {
    return this.store.queryRecord('cart', {
      id: 'any-id',
      include: 'cartItems,cartItems.article,code,delivery_method',
    });
  }

  private async taskCreateCartItemAndAddToCart(product: ProductCart) {
    let cartItem = this.store.createRecord('cart-item', {
      ...product,
      cart: this.cart,
    });
    await cartItem.save();
  }

  private async taskUpdateCartItem(
    cartItem: CartItemModel,
    product: ProductCart
  ) {
    cartItem.setProperties({
      quantity: +cartItem.get('quantity') + +product.quantity,
      cart: this.cart,
    });
    await cartItem.save();
  }

  private async getCartItemAlreadyExists(product: ProductCart) {
    const cartItems = await this.cart?.get('cartItems');
    return cartItems?.find((p) => {
      return p.article.get('id') === product.article.get('id');
    });
  }
}
