import Model, { attr } from '@ember-data/model';
import type { StatusCart } from 'common/src/enums/cart/status.enum';

export default class PromoCodeModel extends Model {
  @attr() declare code: StatusCart;
  @attr() declare promo: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'promo-code': PromoCodeModel;
  }
}
