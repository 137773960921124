
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


d("frontend/adapters/-json-api", function(){ return i("../adapters/-json-api.js");});
d("frontend/adapters/application", function(){ return i("../adapters/application.ts");});
d("frontend/adapters/user", function(){ return i("../adapters/user.ts");});
d("frontend/app", function(){ return i("../app.js");});
d("frontend/component-managers/glimmer", function(){ return i("../component-managers/glimmer.js");});
d("frontend/config/environment", function(){ return i("../config/environment.js");});
d("frontend/data-adapter", function(){ return i("../data-adapter.js");});
d("frontend/flash/object", function(){ return i("../flash/object.js");});
d("frontend/formats", function(){ return i("../formats.js");});
d("frontend/index", function(){ return i("../index.js");});
d("frontend/initializers/app-version", function(){ return i("../initializers/app-version.js");});
d("frontend/initializers/container-debug-adapter", function(){ return i("../initializers/container-debug-adapter.js");});
d("frontend/initializers/ember-data-data-adapter", function(){ return i("../initializers/ember-data-data-adapter.js");});
d("frontend/initializers/ember-data", function(){ return i("../initializers/ember-data.js");});
d("frontend/initializers/ember-simple-auth", function(){ return i("../initializers/ember-simple-auth.js");});
d("frontend/initializers/simple-auth-token", function(){ return i("../initializers/simple-auth-token.js");});
d("frontend/instance-initializers/clear-double-boot", function(){ return i("../instance-initializers/clear-double-boot.js");});
d("frontend/instance-initializers/ember-data", function(){ return i("../instance-initializers/ember-data.js");});
d("frontend/instance-initializers/ember-router-scroll", function(){ return i("../instance-initializers/ember-router-scroll.js");});
d("frontend/instance-initializers/ember-simple-auth", function(){ return i("../instance-initializers/ember-simple-auth.js");});
d("frontend/instance-initializers/head-browser", function(){ return i("../instance-initializers/head-browser.js");});
d("frontend/interfaces/entity-service.interface", function(){ return i("../interfaces/entity-service.interface.ts");});
d("frontend/locations/none", function(){ return i("../locations/none.js");});
d("frontend/models/article", function(){ return i("../models/article.ts");});
d("frontend/models/blog", function(){ return i("../models/blog.ts");});
d("frontend/models/cart-item", function(){ return i("../models/cart-item.ts");});
d("frontend/models/cart", function(){ return i("../models/cart.ts");});
d("frontend/models/delivery-method", function(){ return i("../models/delivery-method.ts");});
d("frontend/models/promo-code", function(){ return i("../models/promo-code.ts");});
d("frontend/models/user", function(){ return i("../models/user.ts");});
d("frontend/router", function(){ return i("../router.js");});
d("frontend/serializers/-default", function(){ return i("../serializers/-default.js");});
d("frontend/serializers/-json-api", function(){ return i("../serializers/-json-api.js");});
d("frontend/serializers/-rest", function(){ return i("../serializers/-rest.js");});
d("frontend/serializers/application", function(){ return i("../serializers/application.ts");});
d("frontend/serializers/cart-item", function(){ return i("../serializers/cart-item.ts");});
d("frontend/serializers/cart", function(){ return i("../serializers/cart.ts");});
d("frontend/services/-ensure-registered", function(){ return i("../services/-ensure-registered.js");});
d("frontend/services/article", function(){ return i("../services/article.ts");});
d("frontend/services/blog", function(){ return i("../services/blog.ts");});
d("frontend/services/bpost-widget", function(){ return i("../services/bpost-widget.ts");});
d("frontend/services/cart", function(){ return i("../services/cart.ts");});
d("frontend/services/contact", function(){ return i("../services/contact.ts");});
d("frontend/services/cookies", function(){ return i("../services/cookies.js");});
d("frontend/services/current-transition", function(){ return i("../services/current-transition.ts");});
d("frontend/services/current-user", function(){ return i("../services/current-user.ts");});
d("frontend/services/dialog-layer", function(){ return i("../services/dialog-layer.js");});
d("frontend/services/fastboot", function(){ return i("../services/fastboot.js");});
d("frontend/services/fetch", function(){ return i("../services/fetch.ts");});
d("frontend/services/filter-query-params", function(){ return i("../services/filter-query-params.ts");});
d("frontend/services/flash-messages", function(){ return i("../services/flash-messages.js");});
d("frontend/services/head-data", function(){ return i("../services/head-data.js");});
d("frontend/services/head-data", function(){ return i("../services/head-data.ts");});
d("frontend/services/intl", function(){ return i("../services/intl.js");});
d("frontend/services/loading", function(){ return i("../services/loading.js");});
d("frontend/services/mondial-relay-widget", function(){ return i("../services/mondial-relay-widget.ts");});
d("frontend/services/page-title-list", function(){ return i("../services/page-title-list.js");});
d("frontend/services/page-title", function(){ return i("../services/page-title.js");});
d("frontend/services/query-params", function(){ return i("../services/query-params.js");});
d("frontend/services/router-scroll", function(){ return i("../services/router-scroll.js");});
d("frontend/services/session", function(){ return i("../services/session.js");});
d("frontend/services/session", function(){ return i("../services/session.ts");});
d("frontend/services/store", function(){ return i("../services/store.js");});
d("frontend/services/user", function(){ return i("../services/user.ts");});
d("frontend/session-stores/application", function(){ return i("../session-stores/application.js");});
d("frontend/tailwind/tailwind.config", function(){ return i("../tailwind/tailwind.config.js");});
d("frontend/transforms/boolean", function(){ return i("../transforms/boolean.js");});
d("frontend/transforms/date", function(){ return i("../transforms/date.js");});
d("frontend/transforms/number", function(){ return i("../transforms/number.js");});
d("frontend/transforms/string", function(){ return i("../transforms/string.js");});
d("frontend/utils/article-slug", function(){ return i("../utils/article-slug.ts");});
d("frontend/utils/bpost-checksum", function(){ return i("../utils/bpost-checksum.ts");});
d("frontend/utils/intl/missing-message", function(){ return i("../utils/intl/missing-message.js");});
d("frontend/utils/map-changeset-errors", function(){ return i("../utils/map-changeset-errors.ts");});
d("frontend/utils/query-param", function(){ return i("../utils/query-param.ts");});
d("frontend/utils/to-pojo", function(){ return i("../utils/to-pojo.js");});
d("frontend/validations/contact", function(){ return i("../validations/contact.ts");});
d("frontend/validations/forgot-password", function(){ return i("../validations/forgot-password.ts");});
d("frontend/validations/login", function(){ return i("../validations/login.ts");});
d("frontend/validations/password-recovery", function(){ return i("../validations/password-recovery.ts");});
d("frontend/validations/user", function(){ return i("../validations/user.ts");});
d("frontend/modifiers/did-insert", function(){ return i("../modifiers/did-insert.js");});
d("frontend/modifiers/did-update", function(){ return i("../modifiers/did-update.js");});
d("frontend/modifiers/focus-trap", function(){ return i("../modifiers/focus-trap.js");});
d("frontend/modifiers/image-magnifier", function(){ return i("../modifiers/image-magnifier.ts");});
d("frontend/modifiers/on-click-outside", function(){ return i("../modifiers/on-click-outside.js");});
d("frontend/modifiers/set-gradient-to-range", function(){ return i("../modifiers/set-gradient-to-range.ts");});
d("frontend/modifiers/will-destroy", function(){ return i("../modifiers/will-destroy.js");});
d("frontend/templates/application", function(){ return i("../templates/application.hbs");});
d("frontend/controllers/application", function(){ return i("../controllers/application.ts");});
d("frontend/routes/application", function(){ return i("../routes/application.ts");});

  if (macroCondition(getGlobalConfig().fastboot?.isRunning)) {
d("frontend/initializers/ajax", function(){ return i("../initializers/ajax.js");});
d("frontend/initializers/error-handler", function(){ return i("../initializers/error-handler.js");});
d("frontend/instance-initializers/setup-fetch", function(){ return i("../instance-initializers/setup-fetch.js");});
  }


w._embroiderRouteBundles_ = [
  {
    names: ["404"],
    load: function() {
      return import("./_route_/404.js");
    }
  },
  {
    names: ["about-us"],
    load: function() {
      return import("./_route_/about-us.js");
    }
  },
  {
    names: ["basket"],
    load: function() {
      return import("./_route_/basket.js");
    }
  },
  {
    names: ["blog.details"],
    load: function() {
      return import("./_route_/blog.details.js");
    }
  },
  {
    names: ["blog.index"],
    load: function() {
      return import("./_route_/blog.index.js");
    }
  },
  {
    names: ["blog"],
    load: function() {
      return import("./_route_/blog.js");
    }
  },
  {
    names: ["catalog.details"],
    load: function() {
      return import("./_route_/catalog.details.js");
    }
  },
  {
    names: ["catalog.index"],
    load: function() {
      return import("./_route_/catalog.index.js");
    }
  },
  {
    names: ["catalog"],
    load: function() {
      return import("./_route_/catalog.js");
    }
  },
  {
    names: ["contact"],
    load: function() {
      return import("./_route_/contact.js");
    }
  },
  {
    names: ["faq"],
    load: function() {
      return import("./_route_/faq.js");
    }
  },
  {
    names: ["forgot-password"],
    load: function() {
      return import("./_route_/forgot-password.js");
    }
  },
  {
    names: ["general-conditions"],
    load: function() {
      return import("./_route_/general-conditions.js");
    }
  },
  {
    names: ["index"],
    load: function() {
      return import("./_route_/index.js");
    }
  },
  {
    names: ["login"],
    load: function() {
      return import("./_route_/login.js");
    }
  },
  {
    names: ["logout"],
    load: function() {
      return import("./_route_/logout.js");
    }
  },
  {
    names: ["novelties"],
    load: function() {
      return import("./_route_/novelties.js");
    }
  },
  {
    names: ["partners"],
    load: function() {
      return import("./_route_/partners.js");
    }
  },
  {
    names: ["profile.address.edit"],
    load: function() {
      return import("./_route_/profile.address.edit.js");
    }
  },
  {
    names: ["profile.address.index"],
    load: function() {
      return import("./_route_/profile.address.index.js");
    }
  },
  {
    names: ["profile.address"],
    load: function() {
      return import("./_route_/profile.address.js");
    }
  },
  {
    names: ["profile.downloads"],
    load: function() {
      return import("./_route_/profile.downloads.js");
    }
  },
  {
    names: ["profile.edit"],
    load: function() {
      return import("./_route_/profile.edit.js");
    }
  },
  {
    names: ["profile.index"],
    load: function() {
      return import("./_route_/profile.index.js");
    }
  },
  {
    names: ["profile.orders"],
    load: function() {
      return import("./_route_/profile.orders.js");
    }
  },
  {
    names: ["profile.vat"],
    load: function() {
      return import("./_route_/profile.vat.js");
    }
  },
  {
    names: ["profile"],
    load: function() {
      return import("./_route_/profile.js");
    }
  },
  {
    names: ["promos"],
    load: function() {
      return import("./_route_/promos.js");
    }
  },
  {
    names: ["reset-password"],
    load: function() {
      return import("./_route_/reset-password.js");
    }
  },
  {
    names: ["head"],
    load: function() {
      return import("./_route_/head.js");
    }
  },
]



if (typeof FastBoot === 'undefined') {
  if (!runningTests) {
    require('frontend/app')['default'].create({"name":"frontend","version":"0.0.0+e49ca83c"});
  }
}


