import Model, { attr, belongsTo } from '@ember-data/model';
import type { CartItemModelInterface } from 'common/src/models/cart-item';
import type ArticleModel from './article';
import type CartModel from './cart';

export default class CartItemModel
  extends Model
  implements CartItemModelInterface
{
  @attr('number') declare quantity: number;
  @attr('number') declare price: number;
  @belongsTo('article') declare article: ArticleModel;
  @belongsTo('cart') declare cart: CartModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'cart-item': CartItemModel;
  }
}
