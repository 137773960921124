import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type FlashMessageService from 'ember-cli-flash/services/flash-messages';
import type CartService from 'frontend/services/cart';
import type CurrentTransitionService from 'frontend/services/current-transition';
import type CurrentUserService from 'frontend/services/current-user';
import type SessionService from 'frontend/services/session';
import type IntlService from 'ember-intl/services/intl';
import { queryParam } from 'ember-query-params-service';

export default class Application extends Route {
  @service declare currentUser: CurrentUserService;
  @service declare session: SessionService;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;
  @service declare currentTransition: CurrentTransitionService;
  @service declare cart: CartService;
  @service declare intl: IntlService;
  @queryParam({ defaultValue: 'fr-fr' }) declare lang: string;

  /**
   * Set current transition to service
   * DO NOT REMOVE
   */
  activate() {
    this.router.on(
      'routeDidChange',
      this.currentTransition.setCurrentTransition
    );
  }

  /**
   * Should never deactivate, but just in case
   */
  deactivate() {
    this.router.off(
      'routeDidChange',
      this.currentTransition.setCurrentTransition
    );
  }

  async beforeModel(t: Transition) {
    if (this.lang === 'fr-fr') {
      this.intl.setLocale(['fr-fr']);
    }
    if (this.lang === 'nl-nl') {
      this.intl.setLocale(['nl-nl']);
    }
    if (this.lang === 'en-us') {
      this.intl.setLocale(['en-us']);
    }
    this.currentTransition.currentTransition = t;
    await this.currentUser.load();
  }
}
