import {
  validateConfirmation,
  validatePresence,
} from 'ember-changeset-validations/validators';
import type { FormsPasswordRecoveryDTO } from 'frontend/components/forms/password-recovery/component';

const formsPasswordRecoveryValidator = {
  password: [
    validatePresence({
      presence: true,
      message: 'validations.password.required',
    }),
  ],
  confirmPassword: [
    validatePresence({
      presence: true,
      message: 'validations.confirmPassword.required',
    }),
    validateConfirmation({
      on: 'password',
      message: 'validations.confirmPassword.notMatch',
    }),
  ],
} as Record<keyof FormsPasswordRecoveryDTO, unknown>;

export default formsPasswordRecoveryValidator;
