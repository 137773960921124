import type emberData__store from '@ember-data/store';
import ArrayProxy from '@ember/array/proxy';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type { TaskGenerator } from 'ember-concurrency';
import { keepLatestTask } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type BlogModel from 'frontend/models/blog';
import type FetchService from './fetch';

export interface Pagination {
  size: string;
  number: string;
}

export default class BlogService extends Service {
  @service declare store: emberData__store;
  @service declare fetch: FetchService;
  @tracked pagination: Pagination | {} = {};
  @tracked blogs: ArrayProxy<BlogModel> = new ArrayProxy();

  @keepLatestTask
  *taskGetBlog(): TaskGenerator<void> {
    this.blogs = yield this.store.query('blog', this.generateQueryPOJO());
  }

  generateQueryPOJO() {
    return {
      page: this.pagination,
    };
  }

  listBlog(): void {
    taskFor(this.taskGetBlog).perform();
  }

  addPagination(pagination: Pagination) {
    this.pagination = pagination;
  }
}
declare module '@ember/service' {
  interface Registry {
    blog: BlogService;
  }
}
