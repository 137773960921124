import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { queryParam } from 'ember-query-params-service';
import type ArticleService from 'frontend/services/article';
import type CartService from 'frontend/services/cart';
import type CurrentUserService from 'frontend/services/current-user';
import type HeadDataService from 'frontend/services/head-data';
import type SessionService from 'frontend/services/session';
import { tracked } from 'tracked-built-ins';

interface HeaderComponentArgs {}

export default class HeaderComponent extends Component<HeaderComponentArgs> {
  @service declare currentUser: CurrentUserService;
  @service declare session: SessionService;
  @service declare headData: HeadDataService;
  @service declare cart: CartService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare article: ArticleService;
  @queryParam declare lang: string;
  @tracked declare selectedLanguage: string;
  @tracked isOpened: boolean = false;

  constructor(owner: unknown, args: HeaderComponentArgs) {
    super(owner, args);
    if (this.lang === 'fr-fr') {
      this.selectedLanguage = 'Français';
    }
    if (this.lang === 'nl-nl') {
      this.selectedLanguage = 'Nederlands';
      this.intl.setLocale(['nl-nl']);
    }
    if (this.lang === 'en-us') {
      this.selectedLanguage = 'English';
      this.intl.setLocale(['en-us']);
    }
  }

  get initials() {
    return `${this.currentUser.user?.firstName[0]} ${this.currentUser.user?.lastName[0]}`;
  }

  get quantitiesInCart() {
    const cartItems = this.cart.cartItems ?? [];
    if (cartItems.length) {
      const quantities: number = cartItems
        .map((cartItem) => cartItem.quantity)
        .reduce((prev: number, current: number) => prev + current, 0);
      return quantities;
    }
    return 0;
  }

  @action
  toggleOpened() {
    this.isOpened = !this.isOpened;
  }

  @action
  changeLanguage(text: string) {
    this.selectedLanguage = text;
    if (this.selectedLanguage === 'Français') {
      this.intl.setLocale(['fr-fr']);
      this.lang = 'fr-fr';
    }
    if (this.selectedLanguage === 'Nederlands') {
      this.intl.setLocale(['nl-nl']);
      this.lang = 'nl-nl';
    }
    if (this.selectedLanguage === 'English') {
      this.intl.setLocale(['en-us']);
      this.lang = 'en-us';
    }
  }
}
