/* eslint-disable unicorn/filename-case */
import Service, { service } from '@ember/service';
import type { EntityServiceInterface } from 'frontend/interfaces/entity-service.interface';
import { toPojo } from 'ember-form-changeset-validations';
import type UserModel from 'frontend/models/user';
import type { TypedBufferedChangeset } from 'ember-form-changeset-validations';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import mapChangesetErrors from 'frontend/utils/map-changeset-errors';
import type emberData__store from '@ember-data/store';
import formsuserValidation from 'frontend/validations/user';

export interface UserDTO {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  password: string;
}

export default class UserService
  extends Service
  implements EntityServiceInterface<UserModel, UserDTO>
{
  @service declare store: emberData__store;

  emptyChangeset(): TypedBufferedChangeset<UserDTO> {
    return Changeset(
      {
        role: '',
        password: '',
        email: '',
        firstName: '',
        lastName: '',
      } as UserDTO,
      lookupValidator(formsuserValidation),
      formsuserValidation
    ) as TypedBufferedChangeset<UserDTO>;
  }

  async changesetFrom(
    id: string | UserModel
  ): Promise<TypedBufferedChangeset<UserDTO>> {
    const user =
      typeof id === 'string' ? this.store.peekRecord('user', id) : id;
    const userPojo = toPojo(user, this.store) as Record<
      keyof UserModel,
      unknown
    >;

    return Changeset(
      userPojo,
      lookupValidator(formsuserValidation),
      formsuserValidation
    ) as TypedBufferedChangeset<UserDTO>;
  }

  async saveChangeset(
    changeset: TypedBufferedChangeset<UserDTO>
  ): Promise<UserModel> {
    changeset.execute();
    const user = changeset.data.id
      ? this.store.peekRecord('user', changeset.data.id)!
      : this.store.createRecord('user');

    user.setProperties({
      role: changeset.data.role,
      password: changeset.data.password,
      firstName: changeset.data.firstName,
      lastName: changeset.data.lastName,
    });

    try {
      await user.save();
    } catch (e) {
      changeset.unexecute();
      user.rollbackAttributes();
      // add errors from backend
      mapChangesetErrors(changeset, e);
    }
    return user;
  }
}
